<!--会员卡管理-->
<!--<div class='Card'></div>-->
<!--
todo
1.多条件查询的功能未实现
-->
<template>
    <div class="Card" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                size="small"
                style="text-align: right"
                v-model="form"
                label-width="85px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组名称">
                            <el-select
                                v-model="form.deptGroupCode"
                                @change="handleChangeGroup"
                                :loading="loadingDeptGroupFlag"
                            >
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="group in meta.groups"
                                    :value="group.code"
                                    :key="group.code"
                                    :label="group.name"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select v-model="form.deptCode" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in meta.deptCodes"
                                    :label="dept.name"
                                    :key="dept.code"
                                    :value="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="开始日期">
                            <el-date-picker
                                type="date"
                                v-model="form.startTime"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="结束日期">
                            <el-date-picker
                                v-model="form.endTime"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="选择日期"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="查询条件">
                            <el-input placeholder="会员名称/手机号码" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="会员卡号">
                            <el-input placeholder="会员卡号" v-model="form.cardPrintNo" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="会员卡类型">
                            <el-select placeholder="会员卡类型" v-model="form.cardType">
                                <el-option label="请选择" value="" />
                                <el-option v-for="cd in meta.cards" :label="cd.name" :value="cd.flag" :key="cd.flag" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="状态">
                            <el-select placeholder="请选择" v-model="form.status">
                                <el-option label="请选择" value="" />
                                <el-option label="正常" value="1" />
                                <el-option label="丢失" value="2" />
                                <el-option label="作废" value="3" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small" v-if="hasPrivilege('menu.member.card.open')"
                >查询</el-button
            >
            <el-button type="primary" @click="handleCreate" size="small" v-if="hasPrivilege('menu.member.card.create')"
                >发卡</el-button
            >
            <el-button type="primary" @click="handleExport" size="small" v-if="hasPrivilege('menu.member.card.export')"
                >导出</el-button
            >
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                ref="table"
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="450"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="会员卡号" width="240" prop="cardPrintNo" v-if="showColumn('cardPrintNo')" />
                <el-table-column
                    label="会员卡类型"
                    width="160"
                    prop="cardType"
                    v-if="showColumn('cardType')"
                    key="cardType"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.cardType | cardType }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="会员名称" width="120" prop="memberName" v-if="showColumn('memberName')" />
                <el-table-column label="会员电话" width="120" prop="mobile" v-if="showColumn('mobile')" />
                <el-table-column label="储值余额" width="120" prop="money" v-if="showColumn('money')" key="money">
                    <template slot-scope="scope">
                        <span>{{ scope.row.money | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="积分" width="100" prop="point" v-if="showColumn('point')" />
                <el-table-column label="发卡时间" width="140" prop="bindDate" v-if="showColumn('bindDate')" />
                <el-table-column label="状态" width="80" prop="status" v-if="showColumn('status')" key="status">
                    <template slot-scope="scope">
                        <span>{{ scope.row.status | status }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="作废时间" width="140" prop="invalidDate" v-if="showColumn('invalidDate')" />
                <el-table-column
                    label="作废机构"
                    width="140"
                    prop="invalidDeptName"
                    v-if="showColumn('invalidDeptName')"
                />

                <el-table-column
                    label="收取工本费"
                    width="100"
                    prop="inWorkCost"
                    v-if="showColumn('inWorkCost')"
                    key="inWorkCost"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.inWorkCost | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="付款方式(收取)" width="130" prop="payType" v-if="showColumn('payType')" />
                <el-table-column
                    label="支出工本费"
                    width="100"
                    prop="outWorkCost"
                    v-if="showColumn('outWorkCost')"
                    key="outWorkCost"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.outWorkCost | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="付款方式(支出)" width="130" prop="outPayType" v-if="showColumn('outPayType')" />
                <el-table-column label="机构名称" width="120" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column
                    label="机构组名称"
                    width="150"
                    prop="deptGroupName"
                    v-if="showColumn('deptGroupName')"
                />
                <el-table-column label="备注" min-width="620" prop="remark" v-if="showColumn('remark')" key="remark">
                    <template slot-scope="scope">
                        <div v-for="r in splitRemark(scope.row.remark)" :key="r">{{ r }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="280"
                    header-align="center"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="success"
                            @click="handleReportLoss(scope.row)"
                            v-if="hasPrivilege('menu.member.card.edit')"
                            :disabled="scope.row.status == 1 ? false : true"
                            >挂失</el-button
                        >
                        <el-button
                            size="mini"
                            type="success"
                            @click="handleRenew(scope.row)"
                            v-if="hasPrivilege('menu.member.card.edit')"
                            :disabled="scope.row.status == 2 ? false : true"
                            >恢复</el-button
                        >
                        <el-button
                            size="mini"
                            type="success"
                            @click="handleReplaceCard(scope.row)"
                            v-if="hasPrivilege('menu.member.card.edit')"
                            :disabled="scope.row.status == 2 ? false : true"
                            >换卡</el-button
                        >
                        <el-button
                            size="mini"
                            type="success"
                            @click="handleReturnCard(scope.row)"
                            v-if="hasPrivilege('menu.member.card.edit')"
                            :disabled="scope.row.status == 1 ? false : true"
                            >退卡</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from '../../../js/Util';
import MoneyUtils from '../../../js/MoneyUtils';
import UrlUtils from '../../../js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
export default {
    name: 'Card',
    components: { CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],

    data() {
        return {
            form: {
                cardType: '',
                deptCode: '',
                cardPrintNo: '',
                status: '',
                search: '',
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                page: 1,
                limit: Util.Limit,
            },
            meta: {
                groups: [],
                deptCodes: [],
                cards: [],
            },
            current: {
                typeIdx: 3,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loadingDeptGroupFlag: true,
            tableData: [],
            url: {
                page: '/member/card/page',
                queryDeptByGroup: '/system/dept/deptTree',
                queryGroups: '/system/deptGroup/list',
            },
        };
    },
    mounted() {
        UrlUtils.GoodsCardType(this, (data) => {
            this.meta.cards = data;
        });
        this.handleQuery();
        UrlUtils.DeptGroup(this, (rst) => {
            this.meta.groups = rst || [];
            this.loadingDeptGroupFlag = false;
        });
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    filters: {
        status(status) {
            if (typeof status == 'undefined') {
                return '';
            }
            if (status == '1') {
                return '正常';
            }
            if (status == '2') {
                return '丢失';
            }
            if (status == '3') {
                return '作废';
            }
        },
        cardType(cardType) {
            if (typeof cardType == 'undefined') {
                return '';
            }
            if (cardType == 'symbol') {
                return '符号卡';
            }
            if (cardType == 'symbol1') {
                return '符号卡1';
            }
        },
        money(money) {
            if (typeof money != 'undefined') {
                return MoneyUtils.formatMoney(MoneyUtils.moneyToYuan(money));
            }
            return '';
        },
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        handleChangeGroup() {
            const _this = this;
            _this.form.deptCode = '';
            UrlUtils.QueryRemote(
                this,
                this.url.queryDeptByGroup + '?deptGroupCode=' + _this.form.deptGroupCode,
                (rst) => {
                    _this.meta.deptCodes = rst;
                }
            );
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleCreate() {
            Util.nameJump(this, 'menu.member.card.create', ['会员管理', '会员卡管理', '发卡']);
        },
        handleReportLoss(row) {
            Util.nameJump(this, 'menu.member.card.reportLoss', ['会员管理', '会员卡管理', '挂失会员卡'], {
                form: row,
                code: row.code,
            });
        },
        handleRenew(row) {
            Util.nameJump(this, 'menu.member.card.reportRenew', ['会员管理', '会员卡管理', '恢复会员卡'], {
                form: row,
                code: row.code,
            });
        },
        handleReturnCard(row) {
            Util.nameJump(this, 'menu.member.card.returnCard', ['会员管理', '会员卡管理', '会员卡退卡'], {
                form: row,
                code: row.code,
            });
        },
        handleReplaceCard(row) {
            Util.nameJump(this, 'menu.member.card.replaceCard', ['会员管理', '会员卡管理', '会员卡换卡'], {
                form: row,
                code: row.code,
            });
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '会员卡', '/member/card/export', this.form, codes);
        },

        splitRemark(remark) {
            if (!remark) return [];
            return remark.split('<-->');
        },
    },
};
</script>

<style scoped>
.Card .el-form-item {
    margin-bottom: 0;
}
</style>
